import React from "react"
import './MediaCard.scss'
import ReactPlayer from "react-player"

const MediaCard = ({media}) => {
  const isMedia = media.link.includes('soundcloud') || media.link.includes('youtube');

  return (
    <div className="MediaCard card">
      {isMedia && <ReactPlayer url={media.link}  width="100%" height="auto" light controls />}
      <div className="card-content">
        {isMedia && media.title}
        {!isMedia && <a href={media.link} target="_blank" rel="noopener noreferrer">{media.title}</a>}
      </div>
    </div>
  )
}

export default MediaCard