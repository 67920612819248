import React from 'react'
import './MediaGallery.scss'
import MediaCard from './MediaCard/MediaCard'

const MediaGallery = ({ media }) => {
  return (
    <div className="MediaGallery">
      {media.map(m => (
        <div
          key={JSON.stringify(m)}
          className="MediaGallery__CardContainer p-sm">
          <MediaCard media={m} />
        </div>
      ))}
    </div>
  )
}

export default MediaGallery
