import { graphql } from "gatsby"
import React from 'react'
import Layout from "../components/layout"
import MediaGallery from "../wal-common/src/components/MediaGallery/MediaGallery"

const MediaTmp = ({data: { markdownRemark: {frontmatter: {media}}}}) => (
  <Layout>
    <MediaGallery media={media} />
  </Layout>
)

export default MediaTmp

export const pageQuery = graphql`
  query Media($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        media {
          title
          link
        }  
      }
    }
  }
`

